import React from 'react';
import axios from 'axios';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from './utils/Utils';

import RouteErrorPage from './utils/RouteErrorPage';
import HomePage from './components/homepage/HomePage';
import Menu from './utils/Menu';
import Login from './components/account/Login';
import UpdateUser from './components/account/UpdateUser';
import UpdateDefaultCurrency from './components/account/UpdateDefaultCurrency';
import ChangePassword from './components/account/ChangePassword';
import ResetPassword from './components/account/ResetPassword';
import ResetPasswordConfirm from './components/account/ResetPasswordConfirm';
import Pos from './utils/Pos';
import GetMpPosDetails from './components/mppos/GetMpPosDetails';
import GetAppPosDetails from './components/apppos/GetAppPosDetails';
import QrCode from './components/mppos/QrCode';
import Cancelled from './components/pos/Cancelled';
import VerifyPayment from './components/pos/VerifyPayment';

axios.defaults.baseURL = 'https://mp-pay.n3connect.com/pos/v0';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route exact path='/' element={ <HomePage /> } errorElement={ <RouteErrorPage /> } />
        <Route path='/account' element={<Menu />} errorElement={ <RouteErrorPage /> } >
          <Route path='login' element={ <Login /> } errorElement={ <RouteErrorPage /> } />
          <Route path='user' element={ <UpdateUser /> } errorElement={ <RouteErrorPage /> } />
          <Route path='default-currency' element={ <UpdateDefaultCurrency /> } errorElement={ <RouteErrorPage /> } />
          <Route path='change-password' element={ <ChangePassword /> } errorElement={ <RouteErrorPage /> } />
          <Route path='reset-password' element={ <ResetPassword /> } errorElement={ <RouteErrorPage /> } />
          <Route path='reset-password-confirm/:uid/:token' element={ <ResetPasswordConfirm /> } errorElement={ <RouteErrorPage /> } />
        </Route>
        <Route path='/mp-pos' element={ <Pos /> } errorElement={ <RouteErrorPage /> } >
          <Route path='details' element={ <GetMpPosDetails /> } errorElement={ <RouteErrorPage /> } />
          <Route path='qr-code' element={ <QrCode /> } errorElement={ <RouteErrorPage /> } />
        </Route>
        <Route path='/app-pos' element={ <Pos /> } errorElement={ <RouteErrorPage /> } >
          <Route path='details' element={ <GetAppPosDetails /> } errorElement={ <RouteErrorPage /> } />
        </Route>
        <Route path='/pos' element={<Menu />} errorElement={ <RouteErrorPage /> } >
          <Route path='cancelled' element={ <Cancelled /> } errorElement={ <RouteErrorPage /> } />
          <Route path='verify/:referenceNumber' element={ <VerifyPayment /> } errorElement={ <RouteErrorPage /> } />
        </Route>
      </Routes>
      <ToastContainer newestOnTop={true} autoClose={4000} limit={3} />
    </ThemeProvider>
  );
}

export default App;
